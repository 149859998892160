
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { addShopPropconf, editShopPropconf, exportShopPropconfList, getBloggerLevelConf, getShopGiftconfList, getShopPropconfList, getShopPropconfRelationModel, getShopPropmodelconfList, sendShopGift, setShopPropconfStatus } from "@/api/request/item";

//组件
@Component({
  name: "ItemConf",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private listQuery: any = {
    id: 0,
    page: 1,
    row: 20,
    model: 0,
    giftid: 0,
    models: [],
    propname: "",
    giftname: "",
  };

  //创建时调用
  created() {
    //显示加载
    this.listLoading = true;

    //获取博主等级
    this.getBloggerList();

    //获取道具关联列表
    this.getRelationList();

    //获取道具资源列表
    this.getItemResList();

    //获取道具类型列表
    this.getPropList();

    //获取道具列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示加载
    this.listLoading = true;

    //请求数据赋值
    const reqParams: any = this.listQuery;
    reqParams.id = GFunc.checkint(this.idStr); //道具ID
    reqParams.giftid = GFunc.checkint(this.giftidStr); //资源ID

    //请求数据
    const { data } = await getShopPropconfList(reqParams);

    //数据赋值
    this.total = data.total;
    this.list = data.list;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取道具类型列表
  private async getPropList() {
    //获取数据
    const { data } = await getShopPropmodelconfList(this.listQuery);

    //数据赋值
    this.propList = GFunc.checkarray(data);
  }

  //处理导出
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //请求数据赋值
    const reqParams: any = this.listQuery;
    reqParams.id = GFunc.checkint(this.idStr); //道具ID
    reqParams.giftid = GFunc.checkint(this.giftidStr); //资源ID

    //请求数据
    const { data } = await exportShopPropconfList(reqParams);

    //保存数据
    saveAs(data, "道具列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取道具资源列表
  private async getItemResList() {
    //获取数据
    const { data } = await getShopGiftconfList({ page: 1, row: GFunc.getListRowLimit() });

    //数据赋值
    this.resList = GFunc.checkarray(GFunc.checktable(data).list);
  }

  //获取博主等级
  private async getBloggerList() {
    //获取数据
    const { data } = await getBloggerLevelConf();

    //数据赋值
    this.useLevelLimit = data;
    this.useLevelLimit.unshift({ level: 0, name: "无限制" });
  }

  //获取关联道具列表
  private async getRelationList() {
    //获取数据
    const { data } = await getShopPropconfRelationModel({});

    //数据赋值
    this.relationMap = data.relation_model;
  }

  //----------------------------- 主界面 -----------------------------
  //定义变量
  private idStr: string = ""; //道具ID
  private tableKey: number = 0; //表格主键
  private giftidStr: string = ""; //资源ID
  private listLoading: boolean = true; // 列表加载

  //数据变量
  private total: number = 0; //总条数
  private list: any[] = []; //道具列表
  private resList: any[] = []; //道具资源列表
  private propList: any[] = []; //道具类型列表
  private useLevelLimit: any[] = []; //使用等级
  private relationMap: { [id: number]: number[] } = {}; //关联道具

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //创建道具
  private handleCreate(): void {
    //重置参数
    this.resetFormData();

    //显示界面
    this.dialogVisible = true;
    this.dialogStatus = "create";
  }

  //处理赠送
  private handleSend(row: any): void {
    //显示界面
    this.dialogSendVisible = true;

    //数据赋值
    this.sendParams.prize[0].gift_id = row.id;
    this.sendParams.prize[0].gift_name = row.name;
  }

  //处理状态
  private handleStatus(row: any): void {
    //提示信息赋值
    const msg = row.status === 0 ? "确定上架该商品？" : "确定下架该商品？";

    //显示提示
    MessageBox.confirm(msg, "提示", {
      type: "warning",
      cancelButtonText: "取消",
      confirmButtonText: "确定",
      dangerouslyUseHTMLString: true,
    })
      .then(async () => {
        //提交修改状态
        await setShopPropconfStatus({ id: row.id, status: row.status === 0 ? 1 : 0 });

        //显示提示
        this.$message.success("操作成功");

        //获取数据
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //显示界面内容
    this.onModelSelectChange(row.model);
    this.handleResSelectChange(row.giftid);
    row.usetimeshow = this.getUseTime(row.usetime);
    row.expiretimeshow = this.getExpireTime(row.expiretime);

    //数据初始化
    this.addForm = GFunc.deepClone(Object.assign({}, row));

    //显示界面
    this.dialogVisible = true;
    this.dialogStatus = "update";
  }

  //-------------------- 表格数据操作 --------------------
  //获取道具类型
  private getPropName(id: number): string {
    //数据赋值
    let prop: any = {};
    id = GFunc.checknumber(id);
    const arr = GFunc.checkarray(this.propList);
    for (let i = 0; i < arr.length; i++) {
      const tmp: any = arr[i];
      if (id === tmp.id) {
        prop = tmp;
        break;
      }
    }

    //返回结果
    return prop ? prop.model_name : "--";
  }

  //获取道具内容ID
  private getDetailID(value: any): string {
    return GFunc.checkstring(value) ? value : "--";
  }

  //获取生效时长
  private getUseTime(time: number): number {
    //数据赋值
    var oneMinute: number = 60;
    var time: number = GFunc.checkint(time);
    return time ? time / oneMinute : 0;
  }

  //获取道具名称
  private getGiftName(name: string): string {
    return GFunc.checkstring(name) ? name : "--";
  }

  //获取有效期限
  private getExpireTime(time: number): number {
    var oneDay: number = 3600 * 24;
    var time: number = GFunc.checkint(time);
    return time ? time / oneDay : 0;
  }

  //获取道具内容名称
  private getDetailName(value: number): string {
    //数据赋值
    let name = "--";
    for (let i = 0; i < this.resList.length; i++) {
      const res = this.resList[i];
      if (value === res.id) {
        name = res.name;
        break;
      }
    }

    //返回数据
    return name + "\n";
  }

  //获取是否绑定名称
  private getBindTypeName(type: number): string {
    //数据赋值
    var type = GFunc.checkint(type);
    var bindType = [
      { type: 0, name: "否" },
      { type: 1, name: "是" },
    ];

    //数据赋值
    let name: string = "--";
    for (let i = 0; i < bindType.length; i++) {
      const bt = bindType[i];
      if (type === bt.type) {
        name = bt.name;
        break;
      }
    }

    //返回数据
    return name;
  }

  //获取关联道具
  private getRelationName(idList: number[]): string {
    //数据赋值
    if (idList.length > 0) {
      //数据赋值
      var name: string = "";
      for (var i: number = 0; i < idList.length; i++) {
        name += idList[i] + ",";
      }
      name = name.substring(0, name.length - 1);

      //返回名称
      return name;
    }

    //返回数据
    return "--";
  }

  //---------------------------- 赠送界面 ----------------------------
  //赠送参数
  private sendParams: any = {
    memo: "",
    prize: [
      {
        gift_id: "",
        gift_name: "",
        gift_num: undefined,
      },
    ],
    type: "1",
    user: [
      {
        id: "",
        name: "",
      },
    ],
  };

  //定义变量
  private sendTags: any = []; //赠送ID
  private sendTagInput: string = ""; //赠送输入
  private dialogSendVisible: boolean = false; //是否显示

  //重置表单
  private sendReset() {
    //数据初始化
    this.sendTags = [];
    this.sendParams.memo = "";
    this.sendParams.type = "1";
    this.sendParams.user = [{ id: "", name: "" }];
    this.sendParams.prize[0].gift_num = undefined;
  }

  //添加赠送ID
  private addSendTag(): void {
    //数据赋值
    if (this.sendTagInput.trim() !== "") {
      //添加成功
      if (GFunc.checkint(this.sendTagInput) != 0) {
        //数据赋值
        this.sendTags.push(this.sendTagInput.trim());

        //清空文本
        this.sendTagInput = "";
      }
      //添加失败
      else {
        //显示提示
        this.$message.error("请输入正确用户ID");

        //清空文本
        this.sendTagInput = "";
      }
    }
  }

  //确定按钮
  private async btnSendSure() {
    //移仓处理
    if (this.sendParams.type === "1" && !this.sendTags.length) return this.$message.error("请输入用户ID");
    if (GFunc.checkint(this.sendParams.prize[0].gift_num) == 0) return this.$message.error("请输入赠送数量");
    if (this.sendParams.memo.trim() == "") return this.$message.error("请输入赠送备注");

    //数据赋值
    const user: any = [];
    if (this.sendTags.length) {
      this.sendTags.forEach((item: any) => {
        user.push({ id: Number(item) });
      });
    }

    //发送数据赋值
    this.sendParams.user = user;
    const data = { ...this.sendParams, type: Number(this.sendParams.type) };

    //发送请求
    const res: any = await sendShopGift(data);

    //结果处理
    if (res.status == 0) {
      //显示提示
      this.$message.success("赠送成功");

      //隐藏界面
      this.dialogSendVisible = false;

      //数据初始化
      this.sendReset();
    }
  }

  //取消按钮
  private btnSendCancel(): void {
    //数据初始化
    this.sendReset();

    //隐藏界面
    this.dialogSendVisible = false;
  }

  //移除赠送ID
  private removeSendTag(index: any): void {
    //数据赋值
    this.sendTags.splice(index, 1);
  }

  //---------------------------- 编辑/创建界面 ----------------------------
  //绑定类型
  private bindType = [
    { type: 0, name: "否" },
    { type: 1, name: "是" },
  ];

  //活动类型
  private targetType = [
    { type: 0, name: "无" },
    { type: 1, name: "元旦烟花" },
  ];

  //VIP等级
  private vipLevels = [
    { type: 0, name: "请选择" },
    { type: 1, name: "VIP1" },
    { type: 2, name: "VIP2" },
    { type: 3, name: "VIP3" },
  ];

  //道具归属
  private belongList = [{ belong: 0, name: "通用" }];

  //定义变量
  private dialogStatus: string = ""; //对话框状态
  private dialogVisible: boolean = false; //是否显示
  private dialogLoading: boolean = false; //对话框等待
  private dialogTitleMap: any = { update: "编辑道具", create: "新增道具" }; //对话框标题

  //表单数据
  private addForm: any = {
    //语言参数
    prop_name_list: {
      zh: "",
      en: "",
      id: "",
    },

    //其他参数
    belong: 0, //道具归属
    bind: 0, // 是否专属 1是
    diamond: 0, // 价格多少钻石
    expiretime: 0, // 有效时间 1可设置
    giftid: 0, // 资源ID
    id: 0, // 道具ID
    integral: 0, // 积分比率 1可设置
    maxlimit: 0, // 设置限制 1可设置
    model: 0, // 归属道具类型
    relationid: 0, // 关联道具ID
    sort: 0, // 设优先级 1可设置
    usecount: 0, // 使用次数 1可设置
    usetime: 0, // 生效时间 1可设置
    value: 0, // 道具价值(用于计算积分)
    viplevel: 0, // VIP等级解锁 1可设置
    relation_ids: [], //关联道具ID列表
    use_level_limit: 0, //使用等级限制
    target_type: 0, //活动类型( 0-无， 1-元旦烟花)
    expiretimeshow: 0, // 过期时间(天) 增加或修改时显示给用户 [增加]
    usetimeshow: 0, // 可使用时长(分) 增加或修改时显示给用户 [增加]
  };

  //数据变量
  private resListShow: any[] = [];
  private resListSelect: any = {};
  private propModelSelect: any = {};

  //关联道具参数
  private relationType: any = {}; //类型
  private relationIndex: number = 0; //索引
  private relationTypeIndex: number = 0; //索引
  private relationTypeValue: number = 0; //类型数据
  private relationList: any[] = []; //道具列表
  private nowRelationList: any[] = []; //当前道具列表
  private relationTypeList: any[] = []; //关联类型列表
  private selectRelationList: any[] = []; //选择道具列表

  //添加数据赋值
  private getAddFormData() {
    //定义变量
    var oneMinute: number = 60;
    var oneDay: number = 3600 * 24;

    //数据赋值
    const tempData = this.addForm;
    tempData.use_level_limit = GFunc.checknumber(tempData.use_level_limit);
    tempData.expiretime = GFunc.checknumber(tempData.expiretimeshow) * oneDay;
    tempData.usetime = GFunc.checknumber(tempData.usetimeshow) * oneMinute;
    tempData.target_type = GFunc.checknumber(tempData.target_type);
    tempData.integral = GFunc.checknumber(tempData.integral);
    tempData.maxlimit = GFunc.checknumber(tempData.maxlimit);
    tempData.usecount = GFunc.checknumber(tempData.usecount);
    tempData.diamond = GFunc.checknumber(tempData.diamond);
    tempData.value = GFunc.checknumber(tempData.value);
    tempData.sort = GFunc.checknumber(tempData.sort);

    //返回数据
    return tempData;
  }

  //更新道具
  private async updateData() {
    //异常处理
    if (!this.checkNameZh()) return;
    if (!this.checkNameEn()) return;
    if (!this.checkNameId()) return;
    if (!this.checkGiftType()) return;

    //数据赋值
    const data = this.getAddFormData();
    const tempData = Object.assign({}, data);

    //编辑数据
    await editShopPropconf(tempData);

    //显示提示
    this.$notify({ title: "成功", message: "更新成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogVisible = false;

    //重新获取数据
    this.getList();
  }

  //创建道具
  private async createData() {
    //异常处理
    if (!this.checkNameZh()) return;
    if (!this.checkNameEn()) return;
    if (!this.checkNameId()) return;
    if (!this.checkGiftType()) return;

    //数据赋值
    const tempData = this.getAddFormData();

    //添加道具
    await addShopPropconf(tempData);

    //显示提示
    this.$notify({ title: "成功", message: "创建成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogVisible = false;

    //重新获取数据
    this.getList();
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //检测名字
  private checkNameZh(): boolean {
    //判断异常
    if (!GFunc.checkstring(this.addForm.prop_name_list.zh)) {
      //显示提示
      Message({
        type: "error",
        duration: 5 * 1000,
        message: "请输入道具名称(中文)！",
      });

      //返回失败
      return false;
    }

    //返回成功
    return true;
  }

  //检测名字
  private checkNameEn(): boolean {
    //判断异常
    if (!GFunc.checkstring(this.addForm.prop_name_list.en)) {
      //显示提示
      Message({
        type: "error",
        duration: 5 * 1000,
        message: "请输入道具名称(英文)！",
      });

      //返回失败
      return false;
    }

    //返回成功
    return true;
  }

  //检测名字
  private checkNameId(): boolean {
    //判断异常
    if (!GFunc.checkstring(this.addForm.prop_name_list.id)) {
      //显示提示
      Message({
        type: "error",
        duration: 5 * 1000,
        message: "请输入道具名称(印尼)！",
      });

      //返回失败
      return false;
    }

    //返回成功
    return true;
  }

  //重置数据
  private resetFormData(): void {
    //初始化数据
    this.addForm = {
      //语言参数
      prop_name_list: {
        zh: "",
        en: "",
        id: "",
      },

      //其他参数
      belong: 0, //道具归属
      bind: 0, // 是否专属 1是
      diamond: 0, // 价格多少钻石
      expiretime: 0, // 有效时间 1可设置
      giftid: 0, // 资源ID
      id: 0, // 道具ID
      integral: 0, // 积分比率 1可设置
      maxlimit: 0, // 设置限制 1可设置
      model: 0, // 归属道具类型
      relationid: 0, // 关联道具ID
      sort: 0, // 设优先级 1可设置
      usecount: 0, // 使用次数 1可设置
      usetime: 0, // 生效时间 1可设置
      value: 0, // 道具价值(用于计算积分)
      viplevel: 0, // VIP等级解锁 1可设置
      relation_ids: [], //关联道具ID列表
      use_level_limit: 0, //使用等级限制
      target_type: 0, //活动类型( 0-无， 1-元旦烟花)
      expiretimeshow: 0, // 过期时间(天) 增加或修改时显示给用户 [增加]
      usetimeshow: 0, // 可使用时长(分) 增加或修改时显示给用户 [增加]
    };

    //清空数据
    this.resListShow = [];
    this.resListSelect = {};
    this.propModelSelect = {};
    this.unshiftToResListShow();

    //关联道具参数
    this.relationType = {}; //类型
    this.relationIndex = 0; //索引
    this.relationList = []; //道具列表
    this.relationTypeIndex = 0; //索引
    this.relationTypeValue = 0; //类型数据
    this.relationTypeList = []; //类型列表
    this.nowRelationList = []; //当前道具列表
    this.selectRelationList = []; //选择道具列表
  }

  //检测类型
  private checkGiftType(): boolean {
    //判断异常
    if (!GFunc.checkint(this.addForm.model)) {
      //显示提示
      Message({
        type: "error",
        duration: 5 * 1000,
        message: "请选择道具类型！",
      });

      //返回失败
      return false;
    }

    //返回成功
    return true;
  }

  //道具资源显示添加
  private unshiftToResListShow(): void {
    //插入空数据
    const nullData = { id: 0, name: "请选择道具资源" };
    this.resListShow.unshift(nullData);
  }

  //获取关联列表
  private async getRelationDetailList() {
    //显示等待
    this.dialogLoading = true;

    //请求数据赋值
    const reqParams: any = { id: 0, page: 1, row: 200, model: 0, giftid: 0, models: [], propname: "", giftname: "" };

    //类型赋值
    reqParams.models = [];
    for (var i: number = 0; i < this.relationTypeList.length; i++) {
      reqParams.models.push(this.relationTypeList[i].id);
    }

    //请求数据
    const { data } = await getShopPropconfList(reqParams);

    //数据赋值
    this.relationList = data.list;

    //关联道具赋值
    for (var i: number = 0; i < this.addForm.relation_ids.length; i++) {
      //数据赋值
      for (var j: number = 0; j < this.relationList.length; j++) {
        if (this.relationList[j].id == this.addForm.relation_ids[i]) {
          this.nowRelationList.push(this.relationList[j]);
          break;
        }
      }
    }

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;
    }, 0.5 * 1000);
  }

  //是否可设置
  private getPropEnable(key: string): boolean {
    //定义变量
    const keys: any = {
      bind: "set_bind",
      sort: "set_sort",
      value: "set_value",
      usetime: "set_usetime",
      viplevel: "set_viplevel",
      expiretime: "set_expire",
      integral: "set_integral",
      maxlimit: "set_maxlimit",
      usecount: "set_usecount",
      belong: "set_belong",
    };

    //判断返回结果
    const realKey = keys[key];
    return !realKey ? false : !GFunc.checkbool(GFunc.checkint(GFunc.checktable(this.propModelSelect)[realKey]) === 1);
  }

  //道具资源显示添加
  private unshiftToRelationTypeListShow(): void {
    //数据赋值
    const nullData = { id: 0, model_name: "请选择关联道具类型" };
    this.relationTypeList.unshift(nullData);
  }

  //选择道具类型
  private onModelSelectChange(value: any): void {
    //道具类型赋值
    this.propModelSelect = {};
    for (let i = 0; i < this.propList.length; i++) {
      //数据赋值
      const res = this.propList[i];

      //判断结束
      if (value === res.id) {
        this.propModelSelect = res;
        break;
      }
    }

    //道具资源数据
    this.resListShow = [];
    for (let i = 0; i < this.resList.length; i++) {
      const res = this.resList[i];
      if (value === res.type) {
        this.resListShow.push(res);
      }
    }
    this.unshiftToResListShow();

    //关联道具数据
    this.nowRelationList = [];
    this.relationTypeList = [];
    this.relationTypeIndex = 0;
    if (this.relationMap[value] && this.relationMap[value].length > 0) {
      for (var i: number = 0; i < this.relationMap[value].length; i++) {
        //数据赋值
        var typeID: number = this.relationMap[value][i];

        //关联类型赋值
        for (var j: number = 0; j < this.propList.length; j++) {
          if (typeID == this.propList[j].id) {
            this.relationTypeList.push(this.propList[j]);
            break;
          }
        }
      }
    }
    if (this.relationTypeList.length > 0) this.getRelationDetailList();
    if (this.relationTypeList.length > 0) this.unshiftToRelationTypeListShow();

    //数据赋值
    this.addForm.giftid = 0;
    this.resListSelect = {};
    this.addForm.model = value;
    this.addForm.relation_ids = [];
  }

  //移除关联道具
  private removeRelationTag(index: number): void {
    //数据赋值
    this.nowRelationList.splice(index, 1);
    this.addForm.relation_ids.splice(index, 1);

    //数据赋值
    if (this.relationTypeValue != 0) {
      //清空数据
      this.relationIndex = 0;
      this.selectRelationList = [];

      //插入空数据
      const nullData = { id: 0, propname: "请选择关联道具" };
      this.selectRelationList.unshift(nullData);

      //数据赋值
      for (var i: number = 0; i < this.relationList.length; i++) {
        if (this.relationList[i].model == this.relationTypeValue && this.addForm.relation_ids.indexOf(this.relationList[i].id) == -1) {
          this.selectRelationList.push(this.relationList[i]);
        }
      }
    }
  }

  //处理道具资源选择
  private handleResSelectChange(value: any): void {
    //数据清空
    this.resListSelect = {};

    //数据赋值
    for (let i = 0; i < this.resListShow.length; i++) {
      //数据赋值
      const res = this.resListShow[i];

      //判断结束
      if (value === res.id) {
        this.resListSelect = res;
        break;
      }
    }
  }

  //创建道具类型
  private get createPropList(): any[] {
    //数据赋值
    var propList: any[] = [];

    //插入空数据
    const nullData = { id: 0, model_name: "请选择道具类型" };
    propList.push(nullData);

    //列表赋值
    for (var i: number = 0; i < this.propList.length; i++) {
      propList.push(this.propList[i]);
    }

    //返回数据
    return propList;
  }

  //处理道具类型选择
  private handleModelSelectChange(value: any): void {
    //重置数据
    this.resetFormData();

    //类型选择
    this.onModelSelectChange(value);
  }

  //处理关联道具选择
  private handleRelationSelectChange(value: any): void {
    //数据赋值
    this.addForm.relation_ids.push(value);
    for (var i: number = 0; i < this.selectRelationList.length; i++) {
      if (this.selectRelationList[i].id == value) {
        this.nowRelationList.push(this.selectRelationList[i]);
      }
    }

    //清空数据
    this.relationIndex = 0;
    this.selectRelationList = [];

    //插入空数据
    const nullData = { id: 0, propname: "请选择关联道具" };
    this.selectRelationList.unshift(nullData);

    //数据赋值
    for (var i: number = 0; i < this.relationList.length; i++) {
      if (this.relationList[i].model == this.relationTypeValue && this.addForm.relation_ids.indexOf(this.relationList[i].id) == -1) {
        this.selectRelationList.push(this.relationList[i]);
      }
    }
  }

  //处理关联道具类型选择
  private handleRelationTypeSelectChange(value: any): void {
    //清空数据
    this.relationIndex = 0;
    this.selectRelationList = [];
    this.relationTypeValue = value;

    //插入空数据
    const nullData = { id: 0, propname: "请选择关联道具" };
    this.selectRelationList.unshift(nullData);

    //数据赋值
    for (var i: number = 0; i < this.relationList.length; i++) {
      if (this.relationList[i].model == value && this.addForm.relation_ids.indexOf(this.relationList[i].id) == -1) {
        this.selectRelationList.push(this.relationList[i]);
      }
    }
  }

  //---------------------------- 图片界面 ----------------------------
  //定义变量
  private dialogImageUrl: any = null;
  private dialogImageVisible: boolean = false;

  //显示图片
  private showImage(url: string): void {
    //数据赋值
    this.dialogImageUrl = url;

    //显示界面
    this.dialogImageVisible = true;
  }
}
